import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Timeline} from "primereact/timeline";
import {Card} from "primereact/card";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {GMap} from "primereact/gmap";
import {Dialog} from "primereact/dialog";
import {TabView, TabPanel} from 'primereact/tabview';
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import currencyFormatter from "currency-formatter";
import {Dropdown} from "primereact/dropdown";
import {Sidebar} from "primereact/sidebar";
import {addLocale} from "primereact/api";

import ProjectReceiptItemsService from "../../services/project/ProjectReceiptItemsService";
import ProjectShowService from "../../services/project/ProjectShowService";
import IdentifierByProjectListService from "../../services/identifier/IdentifierByProjectListService";
import ProductListService from "../../services/product/ProductListService";
import UnitListService from "../../services/unit/UnitListService";
import VatListService from "../../services/vat/VatListService";
import ProjectitemListComponent from "./ProjectitemListComponent";
import ProjectitemFormComponent from "./ProjectitemFormComponent";
import ProjectitemImportComponent from "./ProjectitemImportComponent";
import ReceiptListService from "../../services/receipt/ReceiptListService";
import ReceiptShowComponent from "../receipt/ReceiptShowComponent";
import FulfillmentListComponent from "../fulfillment/FulfillmentListComponent";
import FulfillmentFormComponent from "../fulfillment/FullfillmentFormComponent";
import PurchaseorderItemsComponent from "../purchaseorder/PurchaseorderItemsComponent"
import ProjectSummaryService from "../../services/project/ProjectSummaryService";
import ProjectproductFormComponent from "./ProjectproductFormComponent";
import ProjecttypeShowService from "../../services/projecttype/ProjecttypeShowService";

const ProjectShowComponent = (props) => {

  const {t} = props
  const [formData, setFormData] = useState({
    lng: null,
    lat: null,
  });
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState({});
  const [summary, setSummary] = useState({});
  const [receiptItems, setReceiptItems] = useState([]);
  const [selectedReceiptItems, setSelectedReceiptItems] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [vats, setVats] = useState([]);

  // gmpap
  const google = window.google
  const loadGoogleMaps = (callback) => {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://maps.google.com/maps/api/js?key=' + process.env.REACT_APP_GMAP_KEY;
      script.id = 'googleMaps';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  // const removeGoogleMaps = () => {
  //   const mapScript = document.getElementById('googleMaps');
  //
  //   if (mapScript) {
  //     mapScript.parentNode.removeChild(mapScript);
  //
  //     const head = document.getElementsByTagName('head')[0];
  //     const scripts = head.getElementsByTagName('script');
  //     for (let i = 0; i < scripts.length; i++) {
  //       let script = scripts[i];
  //       let src = script.src;
  //
  //       if (src.startsWith('https://maps.google.com/maps')) {
  //         head.removeChild(script);
  //       }
  //     }
  //   }
  // };

  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [mapDialogVisible, setMapDialogVisible] = useState(false);
  const [mapOverlays, setMapOverlays] = useState(null);
  const [mapSelectedPosition, setMapSelectedPosition] = useState(null);
  const toast = useRef(null);

  //const toast = useRef(null);
  const infoWindow = useRef(null);

  const onMapClick = (event) => {
    setMapDialogVisible(true);
    setMapSelectedPosition(event.latLng)
  }

  const onOverlayClick = (event) => {
    let isMarker = event.overlay.getTitle !== undefined;

    if (isMarker) {
      let title = event.overlay.getTitle();
      infoWindow.current = infoWindow.current || new google.maps.InfoWindow();
      infoWindow.setContent('<div>' + title + '</div>');
      infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());

      toast.current.show({severity: 'info', summary: 'Marker Selected', detail: title});
    } else {
      toast.current.show({severity: 'info', summary: 'Shape Selected', detail: ''});
    }
  }

  const addMarker = () => {
    let newMarker = new google.maps.Marker({
      position: {
        lat: mapSelectedPosition.lat(),
        lng: mapSelectedPosition.lng()
      },
    });

    setMapOverlays([newMarker]);
    setMapDialogVisible(false);
    setFormData({
      ...formData,
      lng: mapSelectedPosition.lng(),
      lat: mapSelectedPosition.lat()
    })
  }

  const onMapReady = (event) => {
    if (props && props.initialValues && props.initialValues.lat && props.initialValues.lng) {
      setMapOverlays(
        [
          new google.maps.Marker({position: {lat: props.initialValues.lat, lng: props.initialValues.lng}, title: ''}),
        ]
      );
    } else {
      setMapOverlays(
        []
      );
    }
  }

  const onMapHide = (event) => {
    setMapDialogVisible(false);
  }

  const mapOptions = {
    center: {lat: 47.49982405077617, lng: 19.064711250233742},
    zoom: 10
  };

  const mapFooter = <div>
    <Button label="Mentés" icon="pi pi-check" className={"p-button-success"} onClick={addMarker}/>
    <Button label="Mégse" className={"p-button-secondary"} onClick={onMapHide}/>
  </div>;

  // charts

  const boxItemsTot = (items) => {
    let tot = 0
    Object.keys(items).forEach((key) => {
      tot = tot + items[key]
    });
    return tot
  }

  // timeline

  const timelineMarker = (item) => {
    return (
      <span className={"custom-marker shadow-1 p-p-1 p-mt-3 p-mb-2"}>
        <i className={item.icon} style={{fontSize: '1.5em'}}></i>
      </span>
    );
  };

  const timelineConent = (item) => {
    return (
      <Card className={"p-mt-3 p-p-1"}>
        <small>{moment.utc(item.date).local().format('YYYY-MM-DD HH:mm:ss')}</small>
        <h3 className={"p-m-0 p-p-0 p-text-secondary"}>
          <span className={"text-" + item.color}>{item.status}</span>
        </h3>
        <p className={"p-mb-0"}>{item.description}</p>
      </Card>
    );
  };

  const timelineData = (items) => {
    let data = []
    items.map((item, index) => {
      data.push({
        status: item.title,
        date: item.date,
        icon: "pi pi-" + item.icon,
        description: item.description,
        color: item.theme,
      })
    })
    return data
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // outgoing receipts

  const [outgoingReceiptsLoading, setOutgoingReceiptsLoading] = useState(false);
  const [outgoingReceiptsTotalRecords, setOutgoingReceiptsTotalRecords] = useState(0);
  const [outgoingReceiptsRows, setOutgoingReceiptsRows] = useState(10);
  const [outgoingReceipts, setOutgoingReceipts] = useState([]);

  let _outgoingReceiptsLazyParams = {
    filters: {
      receiptNumber: {},
      receiptType: {value: 'invoice'},
      outgoing: {value: true},
      projectName: {value: props.initialValues.name},
      partnerName: {},
    },
    first: 0,
    rows: outgoingReceiptsRows,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
  }
  const [outgoingReceiptsLazyParams, setOutgoingReceiptsLazyParams] = useState(_outgoingReceiptsLazyParams);

  const outgoingReceiptsDt = useRef(null);

  let outgoingReceiptsLoadLazyTimeout = null;
  const outgoingReceiptsLoadLazyData = () => {
    setOutgoingReceiptsLoading(true);
    if (outgoingReceiptsLoadLazyTimeout) {
      clearTimeout(outgoingReceiptsLoadLazyTimeout);
    }
    outgoingReceiptsLoadLazyTimeout = setTimeout(() => {
      //window.App.setState({rerenderReceiptList: false})
      ReceiptListService(JSON.stringify(outgoingReceiptsLazyParams))
        .then(response => {
          setOutgoingReceiptsTotalRecords(response.data.total_item_count);
          setOutgoingReceipts(response.data.items);
          setOutgoingReceiptsLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setOutgoingReceiptsLoading(false);
        })
    }, Math.random() * 1000 + 250);
  }
  const outgoingReceiptsOnPage = (event) => {
    let _lazyParams = {...outgoingReceiptsLazyParams, ...event};
    setOutgoingReceiptsLazyParams(_lazyParams);
  }
  const outgoingReceiptsOnSort = (event) => {
    let _lazyParams = {...outgoingReceiptsLazyParams, ...event};
    setOutgoingReceiptsLazyParams(_outgoingReceiptsLazyParams);
  }
  const outgoingReceiptsOnFilter = (event) => {
    let _outgoingReceiptsLazyParams = {...outgoingReceiptsLazyParams, ...event};
    _outgoingReceiptsLazyParams['first'] = 0;
    _outgoingReceiptsLazyParams['page'] = 0;
    setOutgoingReceiptsLazyParams(_outgoingReceiptsLazyParams);
  }
  const outgoingReceiptsPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];
      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setOutgoingReceiptsRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const outgoingReceiptsActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarReceiptShow: true,
                    props: rowData
                  })
                }}/>
      </React.Fragment>
    );
  }

  // incoming receipts

  const [incomingReceiptsLoading, setIncomingReceiptsLoading] = useState(false);
  const [incomingReceiptsTotalRecords, setIncomingReceiptsTotalRecords] = useState(0);
  const [incomingReceiptsRows, setIncomingReceiptsRows] = useState(10);
  const [incomingReceipts, setIncomingReceipts] = useState([]);

  let _incomingReceiptsLazyParams = {
    filters: {
      receiptNumber: {},
      receiptType: {value: 'invoice'},
      outgoing: {value: false},
      projectName: {value: props.initialValues.name},
      partnerName: {},
    },
    first: 0,
    rows: incomingReceiptsRows,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
  }
  const [incomingReceiptsLazyParams, setIncomingReceiptsLazyParams] = useState(_incomingReceiptsLazyParams);

  const incomingReceiptsDt = useRef(null);

  let incomingReceiptsLoadLazyTimeout = null;
  const incomingReceiptsLoadLazyData = () => {
    setIncomingReceiptsLoading(true);
    if (incomingReceiptsLoadLazyTimeout) {
      clearTimeout(incomingReceiptsLoadLazyTimeout);
    }
    incomingReceiptsLoadLazyTimeout = setTimeout(() => {
      //window.App.setState({rerenderReceiptList: false})
      ReceiptListService(JSON.stringify(incomingReceiptsLazyParams))
        .then(response => {
          setIncomingReceiptsTotalRecords(response.data.total_item_count);
          setIncomingReceipts(response.data.items);
          setIncomingReceiptsLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setIncomingReceiptsLoading(false);
        })
    }, Math.random() * 1000 + 250);
  }
  const incomingReceiptsOnPage = (event) => {
    let _lazyParams = {...incomingReceiptsLazyParams, ...event};
    setIncomingReceiptsLazyParams(_lazyParams);
  }
  const incomingReceiptsOnSort = (event) => {
    let _lazyParams = {...incomingReceiptsLazyParams, ...event};
    setIncomingReceiptsLazyParams(_incomingReceiptsLazyParams);
  }
  const incomingReceiptsOnFilter = (event) => {
    let _incomingReceiptsLazyParams = {...incomingReceiptsLazyParams, ...event};
    _incomingReceiptsLazyParams['first'] = 0;
    _incomingReceiptsLazyParams['page'] = 0;
    setIncomingReceiptsLazyParams(_incomingReceiptsLazyParams);
  }
  const incomingReceiptsPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];
      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setIncomingReceiptsRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const incomingReceiptsActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarReceiptShow: true,
                    props: rowData
                  })
                }}/>
      </React.Fragment>
    );
  }

  // products autocomplete
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  const searchProducts = (event) => {
    setSelectedProduct(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProductListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setProducts(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedProduct(string)
        }
      });
  }

  // unit
  const [units, setUnits] = useState([]);

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  //receipt items receiptItemsPager
  const [receiptItemTotalRecords, setReceiptItemTotalRecords] = useState(0);
  const [receiptItemRows, setReceiptItemRows] = useState(10);
  const [receiptItemsLazyParams, setReceiptItemsLazyParams] = useState({
    first: 0,
    receiptItemRows: receiptItemRows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let receiptItemsLoadLazyTimeout = null;
  const receiptItemsLoadLazyData = () => {
    setLoading(true);
    if (receiptItemsLoadLazyTimeout) {
      clearTimeout(receiptItemsLoadLazyTimeout);
    }
    receiptItemsLoadLazyTimeout = setTimeout(() => {
      ProjectReceiptItemsService(props.initialValues.id, JSON.stringify(receiptItemsLazyParams))
        .then(response => {
          setReceiptItemTotalRecords(response.data.total_item_count);
          setReceiptItems(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const receiptItemsOnPage = (event) => {
    let _receiptItemsLazyParams = {...receiptItemsLazyParams, ...event};
    setReceiptItemsLazyParams(_receiptItemsLazyParams);
  }
  const receiptItemsOnSort = (event) => {
    let _receiptItemsLazyParams = {...receiptItemsLazyParams, ...event};
    setReceiptItemsLazyParams(_receiptItemsLazyParams);
  }
  const receiptItemsOnFilter = (event) => {
    let _receiptItemsLazyParams = {...receiptItemsLazyParams, ...event};
    _receiptItemsLazyParams['first'] = 0;
    _receiptItemsLazyParams['page'] = 0;
    setReceiptItemsLazyParams(_receiptItemsLazyParams);
  }
  const receiptItemsPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setReceiptItemRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.receiptItemTotalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadGoogleMaps(() => {
      setGoogleMapsReady(true);
    });
    if (props && props.initialValues) {
      ProjectShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          if (response.data && response.data.projectType && response.data.projectType.id) {
            ProjecttypeShowService(response.data.projectType.id)
              .then(response => {
                setType(response.data)
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }
          ProjectSummaryService(props.initialValues.id)
            .then(response => {
              setSummary(response.data)
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      IdentifierByProjectListService('', props.initialValues.id)
        .then(response => {
          setIdentifiers(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      UnitListService()
        .then(response => {
          setUnits(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      VatListService()
        .then(response => {
          setVats(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    window.App.setState({
      valueOutgonigIsPaid: null,
      valueIncomingIsPaid: null
    })
  }, [])

  useEffect(() => {
    outgoingReceiptsLoadLazyData();
  }, [outgoingReceiptsLazyParams])

  useEffect(() => {
    incomingReceiptsLoadLazyData();
  }, [incomingReceiptsLazyParams])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>{formData.name}</h2>
      <div className={"p-grid"}>
        {formData.projectNumber &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            {t('projectNumber')}
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.projectNumber}</>
          </p>
        </div>
        }
        {formData.partner && formData.partnerName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            {t('partnerName')}
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.partnerName}</>
          </p>
        </div>
        }
        {type && type.fields && type.fields.deadline &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            {t('deadline')}
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            {moment.utc(formData.deadline).local().format('YYYY-MM-DD')}
          </p>
        </div>
        }
        {formData.projectStatus && formData.projectStatus.name &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Státusz
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.projectStatus.name}</>
          </p>
        </div>
        }
      </div>
      <hr/>
      <TabView className="tabview-header-icon menu">
        <TabPanel header="Összefoglaló nézet" leftIcon="pi pi-chart-bar">
          <div className={"p-grid"}>
            {summary && summary.kpi && summary.kpi[0] &&
            [summary.kpi.map((kpi, index) => {
              return (
                <div className={"p-col"}>
                  <Card className={"p-p-1"}>
                    <div className={"p-grid"}>
                      <div className={"p-col-9 p-col-align-center"}>
                        <small className={"p-text-uppercase"}>{kpi.title}</small>
                        <h3 className={"p-m-0 p-p-0 p-text-secondary"}> {kpi.count}</h3>
                      </div>
                      <div className={"p-col-3 p-col-align-center p-text-right"}>
                        <i className={"pi pi-" + kpi.icon} style={{'fontSize': '1.5em'}}></i>
                      </div>
                      <div className={"p-col-12"}>
                          <span className={"text-" + kpi.subtitle_theme}>
                            <span className={"p-text-bold"}>
                              {kpi.subtitle_theme === 'success' &&
                              <i className={"pi pi-arrow-up"}></i>
                              }
                              {kpi.subtitle_theme === 'danger' &&
                              <i className={"pi pi-arrow-down"}></i>
                              }
                              {kpi.subtitle_theme === 'warning' &&
                              <i className={"pi pi-exclamation-triangle"}></i>
                              }
                              &nbsp;{kpi.subtitle}
                            </span> {kpi.description}
                          </span>
                      </div>
                    </div>
                  </Card>
                </div>
              )
            })]
            }
          </div>
          <div className={"p-grid p-mt-2"}>
            <div className={"p-col-12 p-lg-6"}>
              <div className={"p-grid"}>
                {summary && summary.categoryBoxes && summary.categoryBoxes[0] &&
                [summary.categoryBoxes.map((box, boxindex) => {
                  var palettes = [
                    process.env.REACT_APP_COLOR1,
                    process.env.REACT_APP_COLOR2,
                    process.env.REACT_APP_COLOR3,
                    process.env.REACT_APP_COLOR4,
                    process.env.REACT_APP_COLOR5,
                  ];
                  let chartData = []
                  let colorIndexStarter = -1
                  Object.entries(box.items).forEach((item, itemindex) => {
                    const colorIndex = ++colorIndexStarter % palettes.length
                    chartData.push(
                      {
                        type: 'bar',
                        label: item[0] + " (" +
                          currencyFormatter.format(
                            item[1], {
                              precision: 0,
                              symbol: '',
                              decimal: ',',
                              thousand: ' ',
                            }
                          ) + ")",
                        backgroundColor: palettes[colorIndex],
                        data: [item[1]]
                      }
                    )
                  });
                  return (
                    <>
                      <div className={"p-col-12"}>
                        <Card className={"p-p-1"}>
                          <div className={"p-grid"}>
                            <div className={"p-col-10 p-col-align-center"}>
                              <small className={"p-text-uppercase"}>{box.title}</small>
                              <h3 className={"p-m-0 p-p-0 p-text-secondary"}> {box.count}
                                &nbsp;
                                (
                                {currencyFormatter.format(
                                  boxItemsTot(box.items), {
                                    precision: 0,
                                    symbol: '',
                                    decimal: ',',
                                    thousand: ' ',
                                  }
                                )}
                                &nbsp;
                                {box.unit}
                                )
                              </h3>
                            </div>
                            <div className={"p-col-2 p-col-align-center p-text-right"}>
                              <i className={"pi pi-" + box.icon} style={{'fontSize': '1.5em'}}></i>
                            </div>
                            <div className={"p-col-12"}>
                              <Chart type="bar"
                                     data={
                                       {
                                         labels: ['',],
                                         datasets: chartData,
                                       }
                                     }
                                     options={
                                       {
                                         indexAxis: 'y',
                                         maintainAspectRatio: false,
                                         aspectRatio: 3,
                                         plugins: {
                                           legend: {
                                             labels: {
                                               color: '#000'
                                             }
                                           }
                                         },
                                         scales: {
                                           x: {
                                             stacked: true,
                                             ticks: {
                                               color: '#e5e5e5'
                                             },
                                             grid: {
                                               color: '#fff'
                                             }
                                           },
                                           y: {
                                             stacked: true,
                                             ticks: {
                                               color: '#fff'
                                             },
                                             grid: {
                                               color: '#fff'
                                             }
                                           }
                                         }
                                       }
                                     }/>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </>
                  )
                })]
                }
              </div>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <h3 className={"p-mt-0 p-mb-0 p-text-secondary"}>
                <i className={"pi pi-clock"}></i> Előzmények
              </h3>
              {summary.timeline && summary.timeline[0] ? (
                <>
                  <Timeline value={timelineData(summary.timeline)} layout="vertical" align={"left"}
                            marker={timelineMarker} content={timelineConent} className={"no-alternate-column"}/>
                </>
              ) : (
                <>Még nincs előzmény...</>
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Alap adatok" leftIcon="pi pi-list">
          <Panel>
            <div className={"p-grid"}>
              {/*{formData.name &&*/}
              {/*<div className={"p-col-12 p-md-6 p-lg-6"}>*/}
              {/*  <small className={"p-text-uppercase"}>Megnevezés</small>*/}
              {/*  <p className={"p-mt-0 p-mb-0 p-text-bold"}>*/}
              {/*    {formData.name}*/}
              {/*  </p>*/}
              {/*</div>*/}
              {/*}*/}
              {/*{formData.partner && formData.partnerName &&*/}
              {/*<div className={"p-col-12 p-md-6 p-lg-6"}>*/}
              {/*  <small className={"p-text-uppercase"}>{t('partnerName')}</small>*/}
              {/*  <p className={"p-mt-0 p-mb-0 p-text-bold"}>*/}
              {/*    {formData.partnerName}*/}
              {/*  </p>*/}
              {/*</div>*/}
              {/*}*/}
              {formData.description &&
              <div className={"p-col-12 p-lg-12"}>
                <small className={"p-text-uppercase"}>Leírás</small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  {formData.description}
                </p>
              </div>
              }
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-money-bill"}></i> Pénzügy
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {!formData.netAmount && !formData.extraWorkAmount &&
                  <div className={"p-col-12"}>
                    <span className={"p-mt-1"}>Nincs adat.</span>
                  </div>
                  }
                  {formData.netAmount &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Nettó összeg</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {window.App.currencyFormatter(formData.currency, formData.netAmount)}
                    </p>
                  </div>
                  }
                  {formData.extraWorkAmount &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Pótmunka összege</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {window.App.currencyFormatter(formData.currency, formData.extraWorkAmount)}
                    </p>
                  </div>
                  }
                </div>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-info-circle"}></i> Tulajdonságok
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {!formData.projectTypeName && !formData.projectStatusName &&
                  <div className={"p-col-12"}>
                    <span className={"p-mt-1"}>Nincs adat.</span>
                  </div>
                  }
                  {formData.projectTypeName &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Típus</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.projectTypeName}
                    </p>
                  </div>
                  }
                  {formData.projectStatusName &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Státusz</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.projectStatusName}
                    </p>
                  </div>
                  }
                </div>
              </div>
            </div>
            <div className={"p-grid"}>
              {type && type.fields && type.fields.contractNumber &&
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-file"}></i> Szerződés
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {!formData.contractNumber && !formData.projectNumber &&
                  <div className={"p-col-12"}>
                    <span className={"p-mt-1"}>Nincs adat.</span>
                  </div>
                  }
                  {formData.contractNumber &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Szerződésszám</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.contractNumber}
                    </p>
                  </div>
                  }
                  {/*{formData.projectNumber &&*/}
                  {/*<div className={"p-col-12 p-md-6 p-lg-6"}>*/}
                  {/*  <small className={"p-text-uppercase"}>{t('projectNumber')}</small>*/}
                  {/*  <p className={"p-mt-0 p-mb-0 p-text-bold"}>*/}
                  {/*    {formData.projectNumber}*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                  {/*}*/}
                </div>
              </div>
              }
              {type && type.fields && type.fields.deadline &&
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-calendar"}></i> Határidők
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {!formData.startedAt && !formData.guaranteeEndAt && !formData.deadline &&
                  <div className={"p-col-12"}>
                    <span className={"p-mt-1"}>Nincs adat.</span>
                  </div>
                  }
                  {formData.deadline &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <small className={"p-text-uppercase"}>{t('deadline')}</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {moment.utc(formData.deadline).local().format('YYYY-MM-DD')}
                    </p>
                  </div>
                  }
                  {formData.startedAt &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <small className={"p-text-uppercase"}>Munkakezdés</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {moment.utc(formData.startedAt).local().format('YYYY-MM-DD')}
                    </p>
                  </div>
                  }
                  {formData.guaranteeEndAt &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <small className={"p-text-uppercase"}>Garancia lejárat</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {moment.utc(formData.guaranteeEndAt).local().format('YYYY-MM-DD')}
                    </p>
                  </div>
                  }
                </div>
              </div>
              }
              {formData.lng !== null && formData.lat !== null &&
              <>
                <div className={"p-col-12 p-lg-12 p-xl-12"}>
                  <div className={"p-grid p-mt-1"}>
                    <div className={"p-col-12"}>
                      <h3 className="p-text-secondary">
                        <i className={"pi pi-map"}></i> Lokáció
                      </h3>
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      {
                        googleMapsReady && (
                          <div className="card">
                            <GMap overlays={mapOverlays} options={mapOptions}
                                  style={{width: '100%', minHeight: '320px'}}
                                  onMapReady={onMapReady}
                                  onMapClick={onMapClick} onOverlayClick={onOverlayClick}/>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </>
              }
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                        onClick={() => {
                          window.App.setState({
                            sidebarProjectShow: false,
                            sidebarProjectUpdate: true,
                            props: formData
                          })
                        }}
                />
              </div>
            </div>
          </Panel>
          <Panel className={"p-mt-3"} headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3 className={"p-text-secondary p-mt-0"}>
                  <i className={"pi pi-qrcode"}></i> Azonosítók
                </h3>
              </div>
            </div>
          }>
            <div className={"p-grid"}>
              {identifiers && identifiers[0] ? (
                [identifiers.map((identifier, index) => {
                  return (
                    <div className={"p-col p-fluid p-text-center"} style={{maxWidth: '200px'}}>
                      <img src={process.env.REACT_APP_API_HOST + "/identifier/qr/" + identifier.id + "?token="
                      + localStorage.getItem("userToken")} className={"w-100"}/>
                      {identifier.serial ? (
                        <h3>{identifier.serial}</h3>
                      ) : (
                        <h3>{identifier.identifier}</h3>
                      )}
                    </div>
                  )
                })]
              ) : (
                <>
                  Még nincs hozzárendelve azonosító vagy nincs jogosultság az azonosító megtekintéséhez.
                </>
              )}
            </div>
          </Panel>
        </TabPanel>
        <TabPanel header={t('project') + " tételek"} leftIcon="pi pi-list">
          <Panel>
            <ProjectitemListComponent projectId={props.initialValues.id}/>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Tételek importálása" icon="pi pi-upload"
                        className="p-button-secondary p-ml-2"
                        onClick={() => {
                          window.App.setState({
                            dialogProjectitemImport: true,
                          })
                        }}
                />
                <Button type="button" label="Tétel hozzáadása" icon="pi pi-plus" className="p-button-success p-ml-2"
                        onClick={() => {
                          window.App.setState({
                            dialogProjectitem: true,
                            projectitemFormMethod: 'create',
                          })
                        }}
                />
              </div>
            </div>
          </Panel>
          <Dialog visible={window.App.state.dialogProjectitem}
                  style={{width: '750px'}}
                  header="Tétel hozzáadása"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogProjectitem: false
                    })
                  }}>
            <ProjectitemFormComponent projectId={props.initialValues.id}
                                      initialValues={window.App.state.projectitemRowData}/>
          </Dialog>
          <Dialog visible={window.App.state.dialogProjectitemImport}
                  style={{width: '750px'}}
                  header="Tételek importálása"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogProjectitemImport: false
                    })
                  }}>
            <ProjectitemImportComponent projectId={props.initialValues.id} initialValues={window.App.state.props}/>
          </Dialog>
        </TabPanel>
        <TabPanel header="Teljesítések" leftIcon="pi pi-list">
          <Panel>
            <FulfillmentListComponent projectId={props.initialValues.id}/>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Teljesítés hozzáadása" icon="pi pi-plus"
                        className="p-button-success p-ml-2"
                        onClick={() => {
                          window.App.setState({
                            dialogFullfillmentCreate: true,
                          })
                        }}
                />
                <Dialog visible={window.App.state.dialogFullfillmentCreate}
                        style={{width: '750px'}}
                        header="Teljesítés hozzáadása"
                        modal
                        onHide={() => {
                          window.App.setState({
                            dialogFullfillmentCreate: false
                          })
                        }}>
                  <FulfillmentFormComponent projectId={props.initialValues.id}/>
                </Dialog>
              </div>
            </div>
          </Panel>
        </TabPanel>
        <TabPanel header="Számlák" leftIcon="pi pi-list">
          <Panel>
            <h3 className={"p-mt-0 p-pt-0"}>Bevételi számlák</h3>
            <DataTable
              emptyMessage="Nincs találat."
              value={outgoingReceipts} paginator first={outgoingReceiptsLazyParams.first}
              totalRecords={outgoingReceiptsTotalRecords} onPage={outgoingReceiptsOnPage}
              onSort={outgoingReceiptsOnSort} sortField={outgoingReceiptsLazyParams.sortField}
              sortOrder={outgoingReceiptsLazyParams.sortOrder}
              onFilter={outgoingReceiptsOnFilter} filters={outgoingReceiptsLazyParams.filters}
              loading={outgoingReceiptsLoading} lazy
              paginatorTemplate={outgoingReceiptsPager} rows={outgoingReceiptsRows} emptyMessage="Nincs találat."
              autoLayout={true}
              ref={outgoingReceiptsDt}
              filterDisplay="row"
              responsiveLayout="scroll"
              stripedRows
              dataKey="id"
            >
              <Column body={outgoingReceiptsActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
              <Column field="isPaid" header="Fizetve" sortable body={(rowData) => {
                return (
                  <>
                    {rowData.isPaid === true ? (
                      <>Igen<br/>{moment.utc(rowData.paidDate).local().format('YYYY-MM-DD')}</>
                    ) : (
                      <>Nem</>
                    )}
                  </>
                )
              }}
                      filter
                      filterElement={() => {
                        return (
                          <>
                            {outgoingReceiptsLazyParams && outgoingReceiptsLazyParams.filters && outgoingReceiptsLazyParams.filters.isPaid ? (
                              <Dropdown
                                options={[
                                  {
                                    value: true,
                                    name: "Igen"
                                  },
                                  {
                                    value: false,
                                    name: "Nem"
                                  },
                                ]}
                                optionValue={"value"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs státusz..."}
                                onChange={(e) => {
                                  outgoingReceiptsDt.current.filter(e.value, 'isPaid', '');
                                }}
                                value={outgoingReceiptsLazyParams.filters.isPaid.value}
                              />
                            ) : (
                              <Dropdown
                                options={[
                                  {
                                    value: true,
                                    name: "Igen"
                                  },
                                  {
                                    value: false,
                                    name: "Nem"
                                  },
                                ]}
                                optionValue={"value"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs státusz..."}
                                onChange={(e) => {
                                  outgoingReceiptsDt.current.filter(e.value, 'isPaid', '');
                                }}
                              />
                            )}
                          </>
                        )
                      }}
              ></Column>
              <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
              <Column field="issueDate" header="Kelt" sortable className={"p-text-nowrap"} body={(rowData) => {
                return (
                  <>
                    {rowData.issueDate && rowData.issueDate !== null ? (
                      <>{moment.utc(rowData.issueDate).local().format('YYYY-MM-DD')}</>
                    ) : (
                      <></>
                    )
                    }
                  </>
                )
              }}></Column>
              <Column field="deliveryDate" header="Teljesítés" sortable className={"p-text-nowrap"}
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.deliveryDate && rowData.deliveryDate !== null ? (
                              <>{moment.utc(rowData.deliveryDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <></>
                            )
                            }
                          </>
                        )
                      }}></Column>
              <Column field="paymentDate" header="Fiz. határidő" sortable className={"p-text-nowrap"}
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.paymentDate && rowData.paymentDate !== null ? (
                              <>{moment.utc(rowData.paymentDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <></>
                            )
                            }
                          </>
                        )
                      }}></Column>
              <Column field="netAmount" header="Nettó összeg" sortable className={"p-text-right p-text-nowrap"}
                      alignHeader="right"
                      body={(rowData) => {
                        let currency
                        if (rowData.currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.netAmount, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}&nbsp;{rowData.currency}
                          </>
                        )
                      }}></Column>
              <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                      alignHeader="right"
                      body={(rowData) => {
                        let currency
                        if (rowData.currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.grossAmount, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}&nbsp;{rowData.currency}
                          </>
                        )
                      }}></Column>
            </DataTable>
            <h3 className={"p-mt-3"}>Költség számlák</h3>
            <DataTable
              emptyMessage="Nincs találat."
              value={incomingReceipts} paginator first={incomingReceiptsLazyParams.first}
              totalRecords={incomingReceiptsTotalRecords} onPage={incomingReceiptsOnPage}
              onSort={incomingReceiptsOnSort} sortField={incomingReceiptsLazyParams.sortField}
              sortOrder={incomingReceiptsLazyParams.sortOrder}
              onFilter={incomingReceiptsOnFilter} filters={incomingReceiptsLazyParams.filters}
              loading={incomingReceiptsLoading} lazy
              paginatorTemplate={incomingReceiptsPager} rows={incomingReceiptsRows} emptyMessage="Nincs találat."
              autoLayout={true}
              ref={incomingReceiptsDt}
              filterDisplay="row"
              responsiveLayout="scroll"
              stripedRows
              dataKey="id"
            >
              <Column body={incomingReceiptsActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
              <Column field="isPaid" header="Fizetve" sortable
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.isPaid === true ? (
                              <>Igen<br/>{moment.utc(rowData.paidDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <>Nem</>
                            )}
                          </>
                        )
                      }}
                      filter
                      filterElement={() => {
                        return (
                          <>
                            {incomingReceiptsLazyParams && incomingReceiptsLazyParams.filters && incomingReceiptsLazyParams.filters.isPaid ? (
                              <Dropdown
                                options={[
                                  {
                                    value: true,
                                    name: "Igen"
                                  },
                                  {
                                    value: false,
                                    name: "Nem"
                                  },
                                ]}
                                optionValue={"value"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs státusz..."}
                                onChange={(e) => {
                                  incomingReceiptsDt.current.filter(e.value, 'isPaid', '');
                                }}
                                value={incomingReceiptsLazyParams.filters.isPaid.value}
                              />
                            ) : (
                              <Dropdown
                                options={[
                                  {
                                    value: true,
                                    name: "Igen"
                                  },
                                  {
                                    value: false,
                                    name: "Nem"
                                  },
                                ]}
                                optionValue={"value"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs státusz..."}
                                onChange={(e) => {
                                  incomingReceiptsDt.current.filter(e.value, 'isPaid', '');
                                }}
                              />
                            )}
                          </>
                        )
                      }}
              ></Column>
              <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
              <Column field="issueDate" header="Kelt" sortable className={"p-text-nowrap"} body={(rowData) => {
                return (
                  <>
                    {rowData.issueDate && rowData.issueDate !== null ? (
                      <>{moment.utc(rowData.issueDate).local().format('YYYY-MM-DD')}</>
                    ) : (
                      <></>
                    )
                    }
                  </>
                )
              }}></Column>
              <Column field="deliveryDate" header="Teljesítés" sortable className={"p-text-nowrap"}
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.deliveryDate && rowData.deliveryDate !== null ? (
                              <>{moment.utc(rowData.deliveryDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <></>
                            )
                            }
                          </>
                        )
                      }}></Column>
              <Column field="paymentDate" header="Fiz. határidő" sortable className={"p-text-nowrap"}
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.paymentDate && rowData.paymentDate !== null ? (
                              <>{moment.utc(rowData.paymentDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <></>
                            )
                            }
                          </>
                        )
                      }}></Column>
              <Column field="netAmount" header="Nettó összeg" sortable className={"p-text-right p-text-nowrap"}
                      alignHeader="right"
                      body={(rowData) => {
                        let currency
                        if (rowData.currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.netAmount, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}&nbsp;{rowData.currency}
                          </>
                        )
                      }}></Column>
              <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                      alignHeader="right"
                      body={(rowData) => {
                        let currency
                        if (rowData.currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.grossAmount, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}&nbsp;{rowData.currency}
                          </>
                        )
                      }}></Column>
            </DataTable>
            <Sidebar position="left" visible={window.App.state.sidebarReceiptShow} closeOnEscape={true}
                     style={{width: '98%'}}
                     onHide={() => window.App.setState({
                       rerenderReceiptList: true,
                       sidebarReceiptShow: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })}>
              <ReceiptShowComponent initialValues={window.App.state.props}/>
            </Sidebar>
            {/*<DataTable*/}
            {/*  paginator first={receiptItemsLazyParams.first} totalRecords={receiptItemTotalRecords}*/}
            {/*  onPage={receiptItemsOnPage}*/}
            {/*  onSort={receiptItemsOnSort} sortField={receiptItemsLazyParams.sortField}*/}
            {/*  sortOrder={receiptItemsLazyParams.sortOrder}*/}
            {/*  onFilter={receiptItemsOnFilter} filters={receiptItemsLazyParams.filters} loading={loading} lazy*/}
            {/*  paginatorTemplate={receiptItemsPager} rows={receiptItemRows}*/}
            {/*  value={receiptItems}*/}
            {/*  emptyMessage="Nincs találat."*/}
            {/*  autoLayout={true}*/}
            {/*  filterDisplay="row"
responsiveLayout="scroll"*/}
            {/*  stripedRows*/}
            {/*>*/}
            {/*  <Column field="description" header="Megnevezés"></Column>*/}
            {/*  <Column field="quantity" header="Mennyiség" className={"p-text-right"}></Column>*/}
            {/*  <Column field="unitName" header=""></Column>*/}
            {/*  <Column field="unitPrice" header="Egységár" className={"p-text-right"} body={unitPriceBody}></Column>*/}
            {/*  <Column field="netAmount" header="Nettó érték" className={"p-text-right"}*/}
            {/*          body={netAmountPriceBody}></Column>*/}
            {/*  <Column field="vat" header="ÁFA" body={itemVatBody}></Column>*/}
            {/*  <Column field="vatAmount" header="ÁFA érték" className={"p-text-right"}*/}
            {/*          body={vatAmountPriceBody}></Column>*/}
            {/*  <Column field="grossAmount" header="Bruttó érték" className={"p-text-right"}*/}
            {/*          body={grossAmountPriceBody}></Column>*/}
            {/*  <Column field="costTypeName" header="Költségnem"></Column>*/}
            {/*  /!*<Column body={itemActionBody} className={"p-py-0 p-text-nowrap"}></Column>*!/*/}
            {/*</DataTable>*/}
          </Panel>
        </TabPanel>
        {/*<TabPanel header="Alapanyagok" leftIcon="pi pi-list">*/}
        {/*  <Panel>*/}
        {/*    <ProjectproductListComponent projectId={props.initialValues.id}/>*/}
        {/*    <div className={"p-grid p-mt-3"}>*/}
        {/*      <div className={"p-col-12 p-lg-6 p-xl-6"}>*/}
        {/*      </div>*/}
        {/*      <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>*/}
        {/*        <Button type="button" label="Tétel hozzáadása" icon="pi pi-plus" className="p-button-success p-ml-2"*/}
        {/*                onClick={() => {*/}
        {/*                  window.App.setState({*/}
        {/*                    dialogProjectproduct: true,*/}
        {/*                    projectproductFormMethod: 'create',*/}
        {/*                  })*/}
        {/*                }}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Panel>*/}
        {/*  <Dialog visible={window.App.state.dialogProjectproduct}*/}
        {/*          style={{width: '750px'}}*/}
        {/*          header="Tétel hozzáadása"*/}
        {/*          modal*/}
        {/*          onHide={() => {*/}
        {/*            window.App.setState({*/}
        {/*              dialogProjectproduct: false*/}
        {/*            })*/}
        {/*          }}>*/}
        {/*    <ProjectproductFormComponent projectId={props.initialValues.id} initialValues={window.App.state.projectproductRowData}/>*/}
        {/*  </Dialog>*/}
        {/*</TabPanel>*/}
        <TabPanel header="Beszerzések" leftIcon="pi pi-list">
          <Panel>
            <PurchaseorderItemsComponent projectId={props.initialValues.id}/>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Tétel hozzáadása" icon="pi pi-plus" className="p-button-success p-ml-2"
                        onClick={() => {
                          window.App.setState({
                            dialogProjectproduct: true,
                            projectproductFormMethod: 'create',
                          })
                        }}
                />
              </div>
            </div>
          </Panel>
        </TabPanel>
      </TabView>
    </>
  )
}
export default withTranslation('common')(ProjectShowComponent);
