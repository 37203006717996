import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputSwitch} from "primereact/inputswitch";
import {addLocale} from 'primereact/api';
import {Dropdown} from "primereact/dropdown";
import {InputMask} from "primereact/inputmask";
import {AutoComplete} from "primereact/autocomplete";

import SafetyeducationFormService from '../../services/safetyeducation/SafetyeducationFormService'
import SafetyeducationParticipantListService
  from "../../services/safetyeducation/SafetyeducationParticipantListService";
import SafetyeducationShowService from "../../services/safetyeducation/SafetyeducationShowService";
import CompanyListService from "../../services/company/CompanyListService";
import SafetyeducationAttachableParticipantListService
  from "../../services/safetyeducation/SafetyeducationAttachableParticipantListService";
import SafetyWithoutEducationListService from "../../services/safety/SafetyWithoutEducationListService";

const SafetyeducationeducationFormComponent = (props) => {

  const [formData, setFormData] = useState({
    hasExam: false,
  });
  const [loading, setLoading] = useState(false);
  const [educationId, setEducationId] = useState();

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((props && props.initialValues) || formData.id) {
      SafetyeducationFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderSafetyeducationList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarSafetyeducationUpdate: false,
              sidebarSafetyeducationCreate: false
            })
          }
          loadLazyDataEmployeesTarget(response.data.id)
          loadLazyDataEmployeesSource(response.data.id)
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      SafetyeducationFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData({...data, id: response.data.id})
          window.App.setState({rerenderSafetyeducationList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarSafetyeducationCreate: false
            })
          }
          loadLazyDataEmployeesTarget(response.data.id)
          loadLazyDataEmployeesSource(response.data.id)
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  // employees assign
  const [employeesSource, setEmployeesSource] = useState([]);
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesTarget, setEmployeesTarget] = useState([]);
  const [employeesToDelete, setEmployeesToDelete] = useState([]);

  const [loadingEmployeesSource, setLoadingEmployeesSource] = useState(false);
  const [totalRecordsEmployeesSource, setTotalRecordsEmployeesSource] = useState(0);
  const [rowsEmployeesSource, setRowsEmployeesSource] = useState(10);
  const [lazyParamsEmployeesSource, setLazyParamsEmployeesSource] = useState({
    first: 0,
    rows: rowsEmployeesSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutEmployeesSource = null;
  const loadLazyDataEmployeesSource = (entrylocationId) => {
    setLoadingEmployeesSource(true);
    if (loadLazyTimeoutEmployeesSource) {
      clearTimeout(loadLazyTimeoutEmployeesSource);
    }
    loadLazyTimeoutEmployeesSource = setTimeout(() => {
      SafetyeducationAttachableParticipantListService(JSON.stringify(lazyParamsEmployeesSource), entrylocationId)
        .then(response => {
          setTotalRecordsEmployeesSource(response.data.total_item_count);
          setEmployeesSource(response.data.items);
          setLoadingEmployeesSource(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const onSortEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const onFilterEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const pagerEmployeesSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsEmployeesSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const [loadingEmployeesTarget, setLoadingEmployeesTarget] = useState(false);
  const [totalRecordsEmployeesTarget, setTotalRecordsEmployeesTarget] = useState(0);
  const [rowsEmployeesTarget, setRowsEmployeesTarget] = useState(10);
  const [lazyParamsEmployeesTarget, setLazyParamsEmployeesTarget] = useState({
    first: 0,
    rows: rowsEmployeesTarget,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutEmployeesTarget = null;
  const loadLazyDataEmployeesTarget = (educationId) => {
    setLoadingEmployeesTarget(true);
    if (loadLazyTimeoutEmployeesTarget) {
      clearTimeout(loadLazyTimeoutEmployeesTarget);
    }
    loadLazyTimeoutEmployeesTarget = setTimeout(() => {
      SafetyeducationParticipantListService(JSON.stringify(lazyParamsEmployeesTarget), educationId)
        .then(response => {
          setTotalRecordsEmployeesTarget(response.data.total_item_count);
          setEmployeesTarget(response.data.items);
          setLoadingEmployeesTarget(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const onSortEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const onFilterEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const pagerEmployeesTarget = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsEmployeesTarget(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  const serachCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // occupationalSafety autocomplete
  const [occupationalSafeties, setOccupationalSafeties] = useState([]);
  const [selectedOccupationalSafety, setSelectedOccupationalSafety] = useState();

  const searchOccupationalSafety = (event) => {
    setSelectedOccupationalSafety(event.query.replace('Nincs találat', '').replace(': ', ''))
    SafetyWithoutEducationListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setOccupationalSafeties(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedOccupationalSafety(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (formData && formData.id) {
      loadLazyDataEmployeesSource(formData.id);
    }
    if (props && props.initialValues) {
      loadLazyDataEmployeesSource(props.initialValues.id);
      loadLazyDataEmployeesTarget(props.initialValues.id);
      SafetyeducationShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          setSelectedOccupationalSafety(props.initialValues.occupationalSafetyName)
          setSelectedCompany(props.initialValues.companyName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [lazyParamsEmployeesSource, lazyParamsEmployeesTarget])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <div className={"p-grid p-d-flex p-flex-wrap"}>
          <div className={"p-col-12 p-lg-12 p-xl-12 p-d-flex"}>
            <Panel headerTemplate={
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-list"}></i> Alapadatok
                  </h3>
                </div>
              </div>
            } className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                      <span className={"p-d-block"}>
                        <InputText id="name" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       name: e.target.value
                                     })
                                   }}
                                   value={formData.name}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>

                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="company" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="company"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Szervező</label>
                      <span className={"p-d-block"}>
                        <AutoComplete value={selectedCompany}
                                      id={"company"}
                                      suggestions={companies}
                                      forceSelection
                                      completeMethod={serachCompany}
                                      field={"name"}
                                      delay="1000"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          company: {id: e.value.id}
                                        })
                                      }}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          company: {id: null}
                                        })
                                        setSelectedCompany(e.value)
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedCompany('')
                                      }}
                        />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-2 p-lg-2"}>
                  <Field name="code" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="code"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Kód</label>
                      <span className={"p-d-block"}>
                        <InputText id="code" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       code: e.target.value
                                     })
                                   }}
                                   value={formData.code}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-mlg-10 p-xl-10"}>
                  <Field name="description" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="description"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Leírás</label>
                      <span className={"p-d-block"}>
                        <InputText id="description" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       description: e.target.value
                                     })
                                   }}
                                   value={formData.description}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-circle"}></i> Részletek
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-3 p-lg-3"}>
                  <Field name="length" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="length"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Időtartam</label>
                      <span className={"p-d-block"}>
                        <InputText id="length" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       length: e.target.value
                                     })
                                   }}
                                   value={formData.length}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-9 p-lg-9"}>
                  <Field name="location" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="location"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Helyszín</label>
                      <span className={"p-d-block"}>
                        <InputText id="location" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       location: e.target.value
                                     })
                                   }}
                                   value={formData.location}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="applyFrom" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="applyFrom"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Jelentkezés kezdete</label>
                      <span className={"p-d-block"}>
                        <InputMask id={"applyFrom"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.applyFrom}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         applyFrom: e.value
                                       })
                                     }
                                   }}
                        />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="applyUntil" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="applyUntil"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Jelentkezés vége</label>
                      <span className={"p-d-block"}>
                        <InputMask id={"applyUntil"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.applyUntil}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         applyUntil: e.value
                                       })
                                     }
                                   }}
                        />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="date" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="date"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Dátum</label>
                      <span className={"p-d-block"}>
                        <InputMask id={"date"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.date}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         date: e.value
                                       })
                                     }
                                   }}
                        />

                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-9 p-lg-9"}>
                  <Field name="link" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="link"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Link</label>
                      <span className={"p-d-block"}>
                        <InputText id="link" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       link: e.target.value
                                     })
                                   }}
                                   value={formData.link}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-3 p-lg-3"}>
                  <Field name="hasExam" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="hasExam"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Vizsga</label>
                      <span className={"p-d-block"}>
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={formData.hasExam}
                          value={formData.hasExam}
                          onChange={e => setFormData({...formData, hasExam: e.target.value})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-4 p-xl-4"}>

                </div>
                <div className={"p-col-12 p-lg-8 p-xl-8 p-text-right"}>
                  <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success"/>
                  <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(SafetyeducationeducationFormComponent);
