import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";
import {Tooltip} from "primereact/tooltip";

import PurchaseorderrequestListService from "../../services/purchaseorderrequest/PurchaseorderrequestListService";
import PurchaseorderrequestDeleteService from "../../services/purchaseorderrequest/PurchaseorderrequestDeleteService";
import PurchaseorderStatusListService from "../../services/purchaseorder/PurchaseorderStatusListService";
import PurchaseorderrequestStatusListService
  from "../../services/purchaseorderrequest/PurchaseorderrequestStatusListService";

const PurchaseorderrequestListComponent = (props) => {

  const {t} = props;
  const [purchaseorderrequest, setPurchaseorderrequest] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1
  });

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderPurchaseorderrequestList: false})
      PurchaseorderrequestListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setPurchaseorderrequest(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deletePurchaseorderrequest = (rowData) => {
    PurchaseorderrequestDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_purchase_order_req_write')
        && rowData.status === 'new' ? (
          <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                  tooltip="Módosítás"
                  onClick={() => {
                    window.App.setState({
                      sidebarPurchaseorderrequestUpdate: true,
                      props: rowData
                    })
                  }}/>
        ) : (
          <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2 disabled"
          />
        )}
        {localStorage.getItem('roles').toString().includes('role_purchase_order_req_read') &&
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarPurchaseorderrequestShow: true,
                    props: rowData
                  })
                }}/>
        }
        {localStorage.getItem('roles').toString().includes('role_purchase_order_req_write') &&
        <>
          {rowData.status === 'new' ? (
            <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                    tooltip="Törlés"
                    onClick={() => {
                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                        deletePurchaseorderrequest(rowData)
                      }
                    }}
            />
          ) : (
            <>
              <Button icon="pi pi-trash" className="p-button-sm  p-button-danger disabled"
                      tooltip="Törlés"
              />
            </>
          )}
        </>
        }
      </React.Fragment>
    );
  }

  useEffect(() => {
    PurchaseorderrequestStatusListService()
      .then(response => {
        setStatuses(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderPurchaseorderrequestList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={purchaseorderrequest} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
responsiveLayout="scroll"
      ref={dt}
      stripedRows
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="statusName" header="Státusz" sortable filter filterPlaceholder="Státusz"
              filterElement={() => {
                return(
                  <>
                    {lazyParams && lazyParams.filters && lazyParams.filters.status ? (
                      <Dropdown options={statuses}
                                id={"status"}
                                optionValue={"id"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs státusz..."}
                                onChange={(e) => {
                                  dt.current.filter(e.value, 'status', '');
                                }}
                                value={lazyParams.filters.status.value}
                      />
                    ):(
                      <Dropdown options={statuses}
                                id={"status"}
                                optionValue={"id"}
                                optionLabel={"name"}
                                showClear
                                emptyMessage={"Még nincs státusz..."}
                                onChange={(e) => {
                                  dt.current.filter(e.value, 'status', '');
                                }}
                      />
                    )}
                  </>
                )
              }}
      ></Column>
      <Column field="orderNumber" header="Rendelés szám" sortable filter filterPlaceholder="Rendelés szám"></Column>
      <Column field="deadline" header="Határidő" sortable body={(rowData) => {
        return (
          <>{moment.utc(rowData.deadline).local().format('YYYY-MM-DD')}</>
        )
      }}></Column>
      <Column field="createdAt" header="Létrehozva" sortable body={(rowData) => {
        return (
          <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
        )
      }}></Column>
      <Column field="projectName" header={t('project')} sortable filter filterPlaceholder={t('project')}></Column>
      <Column field="requestedByName" header="Igénylő" sortable></Column>
      <Column field="createdByName" header="Rögzítette" sortable></Column>
    </DataTable>

  )
}
export default withTranslation('common')(PurchaseorderrequestListComponent);
