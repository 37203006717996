import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";

import ScheduleProductionitemsService from "../../services/schedule/ScheduleProductionitemsService";
import GuestStatusService from "../../services/guest/GuestStatusService";
import ProductionitemStatusesService from "../../services/productionitem/ProductionitemStatusesService";
import ProductionjobListService from "../../services/productionjob/ProductionjobListService";

const ScheduleProductionitemsComponent = (props) => {

  const {t} = props;
  const [scheduleProductionitems, setScheduleProductionitems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderScheduleProdictionitems: false})
      let _lazyParams = {
        ...lazyParams,
        filters: {
          schedule: {
            value: window.App.state.props.id
          }
        }
      }
      ProductionjobListService(JSON.stringify(_lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setScheduleProductionitems(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })

      // ScheduleProductionitemsService(JSON.stringify(lazyParams), props.scheduleId)
      //   .then(response => {
      //     setTotalRecords(response.data.total_item_count);
      //     setScheduleProductionitems(response.data.items);
      //     setLoading(false);
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //   })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*{/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteSchedule = (rowData) => {
    // ScheduleDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {/*{localStorage.getItem('roles').toString().includes('role_schedule_write') &&*/}
        {/*<Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"*/}
        {/*        tooltip="Módosítás"*/}
        {/*        onClick={() => {*/}
        {/*          window.App.setState({*/}
        {/*            sidebarScheduleUpdate: true,*/}
        {/*            props: rowData*/}
        {/*          })*/}
        {/*        }}/>*/}
        {/*}*/}
        {/*{localStorage.getItem('roles').toString().includes('role_schedule_read') &&*/}
        {/*<Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"*/}
        {/*        tooltip="Megtekintés"*/}
        {/*        onClick={() => {*/}
        {/*          console.log(rowData)*/}
        {/*          window.App.setState({*/}
        {/*            sidebarScheduleShow: true,*/}
        {/*            props: rowData,*/}
        {/*          })*/}
        {/*        }}/>*/}
        {/*}*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteSchedule(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }

  const [statuses, setStatuses] = useState([]);
  const status = (rowData) => {
    if (statuses && statuses[0]) {
      let status_ = statuses.filter(item => item.id === rowData.status)
      if (status_[0] && status_[0].name) {
        return status_[0].name
      }
    }
  }

  useEffect(() => {
    ProductionitemStatusesService()
      .then((response) => {
        setStatuses(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderScheduleProdictionitems === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={scheduleProductionitems} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
      >
        {/*<Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>*/}
        <Column field="status" header="Státusz" sortable body={status}></Column>
        <Column field="projectName" header={t('project')} sortable filter filterPlaceholder={t('project')}></Column>
        <Column field="projectItemNumber" header="Munkaszám" sortable filter filterPlaceholder="Munkaszám"></Column>
        <Column field="technologyName" header="Technológia" sortable filter filterPlaceholder="Technológia"></Column>
        <Column field="machineName" header="Gép" sortablefilter filterPlaceholder="Gép"></Column>
        <Column field="employeeName" header="Munkavállaló" sortable filter filterPlaceholder="Munkavállaló"></Column>
        <Column field="estimatedTime" header="Becsült" sortable body={(rowData) => {
          return (
            <>
              {rowData.estimatedTime &&
              <>{rowData.estimatedTime} perc</>
              }
            </>
          )
        }}></Column>
        <Column field="producedTime" header="Valós" sortable body={(rowData) => {
          return (
            <>
              {rowData.producedTime &&
              <>{rowData.producedTime} perc</>
              }
            </>
          )
        }}></Column>
        <Column field="mainTime" header="Főidő" sortable body={(rowData) => {
          return (
            <>
              {rowData.mainTime &&
              <>{rowData.mainTime} perc</>
              }
            </>
          )
        }}></Column>
        <Column field="auxiliaryTime" header="Mellékidő" sortable body={(rowData) => {
          return (
            <>
              {rowData.auxiliaryTime &&
              <>{rowData.auxiliaryTime} perc</>
              }
            </>
          )
        }}></Column>
        <Column field="quantity" header="Mennyiség" className={"p-text-right"} alignHeader="right" body={(rowData) => {
          return (
            <>
              {rowData.quantity} {rowData.unitName}
            </>
          )
        }}></Column>
        {/*<Column field="dateUntil" header="Időpontig" sortable className={"p-text-nowrap"} body={(rowData) => {*/}
        {/*  return (*/}
        {/*    <>{moment.utc(rowData.dateUntil).local().format('YYYY-MM-DD HH:mm')}</>*/}
        {/*  )*/}
        {/*}}></Column>*/}
      </DataTable>
    </>
  )
}
export default withTranslation('common')(ScheduleProductionitemsComponent);
