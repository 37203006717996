import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import {SplitButton} from "primereact/splitbutton";

import OrderListService from "../../services/order/OrderListService";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import OrderCreateReceiptService from "../../services/order/OrderCreateReceiptService";
import moment from "moment";
import ReceiptFormComponent from "../receipt/ReceiptFormComponent";
import {Sidebar} from "primereact/sidebar";
import {Message} from "primereact/message";
// import OrderDeleteService from "../../services/order/OrderDeleteService";

const OrderListComponent = (props) => {

  const [orders, setOrders] = useState([]);
  const [receiptGroups, setReceiptGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderOrderList: false})
      OrderListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setOrders(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteOrder = (rowData) => {
    // OrderDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    let _receiptGroups = []
    receiptGroups.map(function (item, index) {
      if (item) {
        _receiptGroups.push({
          label: item.name,
          icon: 'pi pi-plus',
          command: () => {
            setLoading(true)
            OrderCreateReceiptService(rowData.id, item.id)
              .then(response => {
                setLoading(false)
                window.App.setState({
                  props: {
                    id: response.data.receiptId,
                    outgoing: item.outgoing,
                    external: item.external,
                    receiptType: item.type
                  },
                  sidebarReceiptUpdate: true,
                })
                setTimeout(() => {
                  window.App.setState({
                    rerenderOrderList: true,
                  })
                }, 1000)
              })
              .catch(error => {
                setTimeout(() => {
                  window.App.setState({
                    rerenderOrderList: true,
                  })
                }, 1000)
                setLoading(false)
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }
        })
      }
    })
    return (
      <React.Fragment>
        {/*{localStorage.getItem('roles').toString().includes('role_order_write') &&*/}
        {/*<Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"*/}
        {/*        tooltip="Módosítás"*/}
        {/*        onClick={() => {*/}
        {/*          window.App.setState({*/}
        {/*            sidebarOrderUpdate: true,*/}
        {/*            props: rowData*/}
        {/*          })*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
        {localStorage.getItem('roles').toString().includes('role_order_read') &&
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarOrderShow: true,
                    props: rowData
                  })
                }}/>
        }
        {localStorage.getItem('roles').toString().includes('role_receipt_write') &&
        <>
          {rowData.partner !== null ? (
            <>
              <SplitButton icon="pi pi-file" model={_receiptGroups}
                           className={"p-button-sm p-button-info p-text-light"}/>
            </>
          ) : (
            <>
              <SplitButton icon="pi pi-file" disabled className={"p-button-sm p-button-info p-text-light"}/>
            </>
          )}
        </>
        }
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteOrder(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }

  useEffect(() => {
    ReceiptgroupListService(JSON.stringify({
      filters: {
        receiptType: {
          value: 'invoice'
        },
        outgoing: {
          value: true
        },
        external: {
          value: false
        }
      }
    }))
      .then(response => {
        setReceiptGroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderOrderList === true])

  return (
    <>
      {/*<Message severity={"warn"} className={"p-mb-1"}*/}
      {/*         text={"Kérjük, hogy a megrendelések szinkronizációja érdekében a Beállítások / UNAS menüpont alatt ellenőrizd beállításaidat!"}/>*/}
      <DataTable
        emptyMessage="Nincs találat."
        value={orders} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="orderNumber" header="Rendelésszám" sortable filter filterPlaceholder="Rendelésszám"></Column>
        <Column field="autoReceipt" header="Automata számlázás" sortable className={"p-text-center"}
                body={(rowData) => {
                  return (
                    <>
                      {rowData.autoReceipt === true ? (
                        <i className={"pi pi-check text-success"}></i>
                      ) : (
                        <i className={"pi pi-times text-danger"}></i>
                      )}
                    </>
                  )
                }}
        ></Column>
        <Column field="hasReceipt" header="Számlázva" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <>
              {rowData.hasReceipt === true ? (
                <i className={"pi pi-check text-success"}></i>
              ) : (
                <i className={"pi pi-times text-danger"}></i>
              )}
            </>
          )
        }}
        ></Column>
        <Column field="partnerName" header="Vevő neve" sortable
          // filter filterPlaceholder="Vevő neve"
        ></Column>
        <Column field="paymentMethodName" header="Fizetési mód" sortable></Column>
        <Column field="paid" header="Fizetve" sortable className={"p-text-center"} body={(rowData) => {
          return (
            <>
              {rowData.isPaid === true ? (
                <i className={"pi pi-check text-success"}></i>
              ) : (
                <i className={"pi pi-times text-danger"}></i>
              )}
            </>
          )
        }}
        ></Column>
        <Column field="orderDate" header="Megrendelve" sortable body={(rowData) => {
          return (
            <>{moment.utc(rowData.orderDate).local().format('YYYY-MM-DD HH:mm:ss')}</>
          )
        }}></Column>
      </DataTable>
      <Sidebar position="left" visible={window.App.state.sidebarReceiptUpdate} closeOnEscape={true}
               style={{width: '98%'}}
               onHide={() => window.App.setState({
                 sidebarReceiptUpdate: false,
               })}>
        <ReceiptFormComponent method="put" initialValues={window.App.state.props}/>
      </Sidebar>
    </>

  )
}
export default withTranslation('common')(OrderListComponent);
