import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import moment from "moment";
import {TabView, TabPanel} from "primereact/tabview";
import download from "js-file-download";
import {Button} from "primereact/button";

import OfferShowService from "../../services/offer/OfferShowService";
import OfferFormComponent from "./OfferFormComponent";
import OfferShowComponent from "./OfferShowComponent";
import OfferitemListComponent from "./OfferitemListComponent";
import OfferstatusListService from "../../services/offerstatus/OfferstatusListService";
import OfferDownloadService from "../../services/offer/OfferDownloadService";
import OfferStatisService from "../../services/offer/OfferStatusService";
import OfferStartProjectService from "../../services/offer/OfferStartProjectService";

const OfferViewComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [statuses, setStatuses] = useState([]);
  const status = (rowData) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === rowData.status)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }
  useEffect(() => {
    OfferShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
        window.App.setState({rerenderOfferView: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    OfferstatusListService()
      .then(response => {
        setStatuses(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [window.App.state.rerenderOfferView === true])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>{formData.offerNumber}</h2>
      <div className={"p-grid"}>
        {formData.status &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Státusz
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{status(formData)}</>
          </p>
        </div>
        }
        {formData.partnerName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Partner
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.partnerName}</>
          </p>
        </div>
        }
        {formData.sumGrossAmount &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Összérték
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.sumGrossAmount}</>
          </p>
        </div>
        }
        {formData.companyName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Vállalkozás
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.companyName}</>
          </p>
        </div>
        }
      </div>
      <hr/>
      <TabView className="tabview-header-icon menu" renderActiveOnly="true"
               activeIndex={window.App.state.activeindexOfferView}>
        <TabPanel header="Alap adatok" leftIcon="pi pi-list">
          <Panel>
            {window.App.state.modeOfferView === 'show' &&
            <>
              <OfferShowComponent initialValues={window.App.state.props} />
            </>
            }
            {window.App.state.modeOfferView === 'edit' &&
            <>
              <OfferFormComponent initialValues={window.App.state.props}/>
            </>
            }
          </Panel>
        </TabPanel>
        <TabPanel header="Árajánlat tételek" leftIcon="pi pi-list">
          <Panel>
            <OfferitemListComponent initialValues={window.App.state.props} />
          </Panel>
        </TabPanel>
      </TabView>
      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-6"}>
          {formData && formData.status && formData.status === 'DRAFT' &&
          <Button type="button" label="Ajánlat kiküldve" icon="pi pi-envelope" className="p-button-highlighted p-mr-2"
                  onClick={() => {
                    OfferStatisService(window.App.state.props.id, 'SENT')
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres státusz módosítás!');
                        window.App.setState({rerenderOfferList: true})
                        setFormData(response.data)
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })
                  }} />
          }
          {formData && formData.status && formData.status === 'SENT' &&
            <>
              <Button type="button" label="Ajánlat elfogadva" icon="pi pi-check" className="p-button-highlighted p-mr-2"
                      onClick={() => {
                        OfferStatisService(window.App.state.props.id, 'ACCEPTED')
                          .then(response => {
                            window.App.toastShow('success', 'Sikeres státusz módosítás!');
                            window.App.setState({rerenderOfferList: true})
                            setFormData(response.data)
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      }} />
              <Button type="button" label="Ajánlat visszavonása" icon="pi pi-times" className="p-button-highlighted p-mr-2"
                      onClick={() => {
                        OfferStatisService(window.App.state.props.id, 'FAILED')
                          .then(response => {
                            window.App.toastShow('success', 'Sikeres státusz módosítás!');
                            window.App.setState({rerenderOfferList: true})
                            setFormData(response.data)
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      }} />
            </>
          }
          {formData && formData.status && formData.status === 'ACCEPTED' &&
          <>
            <Button type="button" label="Ajánlat visszavonása" icon="pi pi-times" className="p-button-highlighted p-mr-2"
                    onClick={() => {
                      OfferStatisService(window.App.state.props.id, 'FAILED')
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres státusz módosítás!');
                          window.App.setState({rerenderOfferList: true})
                          setFormData(response.data)
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }} />
            {formData.project && formData.project.id ? (
              <>
                <Button type="button" label={t('project') + " megtekintése"} icon="pi pi-eye" className="p-button-warning p-mr-2"
                        onClick={() => {
                          window.App.setState({
                            sidebarOfferView: false,
                            sidebarProjectShow: true,
                          })
                          props = {
                            initialValues: {
                              id: formData.project.id
                            }
                          }
                        }}
                         />
              </>
            ):(
              <>
                <Button type="button" label={t('project') + " indítása"} icon="pi pi-plus" className="p-button-highlighted p-mr-2"
                        onClick={() => {
                          OfferStartProjectService(window.App.state.props.id)
                            .then(response => {
                              window.App.setState({
                                props: response.data,
                                sidebarOfferView: false,
                                sidebarProjectUpdate: true,
                              })
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        }} />
              </>
            )}

          </>
          }
          {formData && formData.status && formData.status === 'FAILED' &&
          <Button type="button" label="Ajánlat újranyitása" icon="pi pi-check" className="p-button-highlighted p-mr-2"
                  onClick={() => {
                    OfferStatisService(window.App.state.props.id, 'DRAFT')
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres státusz módosítás!');
                        setFormData(response.data)
                        window.App.setState({rerenderOfferList: true})
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })
                  }} />
          }
        </div>
        <div className={"p-col-12 p-lg-6 p-text-right"}>
          <Button type="button" label="Export" icon="pi pi-download"
                  onClick={() => {
                    OfferDownloadService(window.App.state.props.id, 'xlsx')
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres letöltés!');
                        download(response.data, localStorage.getItem('networkName')
                          + '_'
                          + 'arajanlat'
                          + '_'
                          + props.initialValues.id
                          + '_'
                          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                          + '.xlsx')
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })
                  }}
                  className="p-button p-ml-2"/>
          <Button type="button" label="Letöltés" icon="pi pi-download"
                  onClick={() => {
                    OfferDownloadService(window.App.state.props.id, 'pdf')
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres letöltés!');
                        download(response.data, localStorage.getItem('networkName')
                          + '_'
                          + 'arajanlat'
                          + '_'
                          + props.initialValues.id
                          + '_'
                          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                          + '.pdf')
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })
                  }}
                  className="p-button p-ml-2"/>
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(OfferViewComponent);
