import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputNumber} from "primereact/inputnumber";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";

import PurchaseorderRequestItemsAddedService from "../../services/purchaseorder/PurchaseorderRequestItemsAddedService";
import PurchaseorderRequestItemsUpdateService
  from "../../services/purchaseorder/PurchaseorderRequestItemsUpdateService";
import WarehouseListService from "../../services/warehouse/WarehouseListService";
import PurchaseorderDeliverynoteCreateService
  from "../../services/purchaseorder/PurchaseorderDeliverynoteCreateService";
import PartnerListService from "../../services/partner/PartnerListService";
import CompanyListService from "../../services/company/CompanyListService";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import ReceiptListService from "../../services/receipt/ReceiptListService";
import PurchaseorderShowService from "../../services/purchaseorder/PurchaseorderShowService";
import PurchaseorderStatusChangeArrivedService
  from "../../services/purchaseorder/PurchaseorderStatusChangeArrivedService";
import {InputMask} from "primereact/inputmask";

const PurchaseorderStockComponent = (props) => {

  const {t} = props;
  const [items, setItems] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [defaultWarehouse, setDefaultWarehouse] = useState(null);

  const [loadingItems, setLoadingItems] = useState(false);
  const [totalRecordsItems, setTotalRecordsItems] = useState(0);
  const [rowsItems, setRowsItems] = useState(10);
  const [lazyParamsItems, setLazyParamsItems] = useState({
    first: 0,
    rows: rowsItems,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutItems = null;
  const loadLazyDataItems = () => {
    setLoadingItems(true);
    if (loadLazyTimeoutItems) {
      clearTimeout(loadLazyTimeoutItems);
    }
    loadLazyTimeoutItems = setTimeout(() => {
      PurchaseorderRequestItemsAddedService(props.orderId, JSON.stringify(lazyParamsItems))
        .then(response => {
          setTotalRecordsItems(response.data.total_item_count);
          setItems(response.data.items);
          setLoadingItems(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageItems = (event) => {
    let _lazyParams = {...lazyParamsItems, ...event};
    setLazyParamsItems(_lazyParams);
  }
  const onSortItems = (event) => {
    let _lazyParams = {...lazyParamsItems, ...event};
    setLazyParamsItems(_lazyParams);
  }
  const onFilterItems = (event) => {
    let _lazyParams = {...lazyParamsItems, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsItems(_lazyParams);
  }
  const pagerItems = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsItems(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.partner) {
    //   errors.partner = 'A partner nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    PurchaseorderDeliverynoteCreateService(data, props.orderId)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        setFormData(response.data)
        window.App.setState({
          dialogDeliverynote: false,
        })
        ReceiptListService(
          JSON.stringify({
            filters: {
              purchaseOrder: {
                value: props.initialValues.id
              },
            }
          })
        )
          .then(response => {
            setReceipts(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // partner autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartner = (event) => {
    PartnerListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setPartners(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  //receiptGroup
  const [receiptgroups, setReceiptgroups] = useState([]);

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    let _formData
    WarehouseListService(
      JSON.stringify({
        first: 0,
        rows: 9999,
        page: 0,
      })
    )
      .then(response => {
        setWarehouses(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyDataItems(props.orderid);

    if (localStorage.getItem('defaultCompanyId') && localStorage.getItem('defaultCompanyName')) {
      setSelectedCompany(localStorage.getItem('defaultCompanyName'))
      _formData = {
        ..._formData,
        company: {id: Number(localStorage.getItem('defaultCompanyId'))}
      }
    }
    ReceiptgroupListService(
      JSON.stringify({
        filters: {
          receiptType: {value: 'delivery_note'},
          external: {value: true},
          outgoing: {value: false},
        }
      })
    )
      .then(response => {
        setReceiptgroups(response.data.items)
        if (response.data.items && response.data.items[0] && !response.data.items[1]) {
          _formData = {
            ..._formData,
            receiptGroup: {id: response.data.items[0].id}
          }
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ReceiptListService(
      JSON.stringify({
        filters: {
          purchaseOrder: {
            value: props.initialValues.id
          },
        }
      })
    )
      .then(response => {
        setReceipts(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    _formData = {
      ..._formData,
      deliveryDate: moment.utc(new Date()).local().format('YYYY-MM-DD'),
      issueDate: moment.utc(new Date()).local().format('YYYY-MM-DD'),
    }
    setFormData(_formData)
  }, [lazyParamsItems])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={items} paginator first={lazyParamsItems.first}
        totalRecords={totalRecordsItems} onPage={onPageItems}
        onSort={onSortItems} sortField={lazyParamsItems.sortField}
        sortOrder={lazyParamsItems.sortOrder}
        onFilter={onFilterItems} filters={lazyParamsItems.filters}
        loading={loadingItems} lazy
        paginatorTemplate={pagerItems} rows={rowsItems}
        emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
      >
        <Column field="projectName" header={t('project')} sortable filter filterPlaceholder={t('project')}></Column>
        <Column field="productName" header="Termék" sortable filter filterPlaceholder="Termék"></Column>
        <Column field="articleNumber" header="Cikkszám" sortable filter filterPlaceholder="Cikkszám"></Column>
        <Column field="quantity" header="Mennyiség" sortable filter filterPlaceholder="Mennyiség"
                className={"p-text-right"} alignHeader="right"></Column>
        <Column field="unitName" header="M.e." sortable className={"p-text-right"}></Column>
        <Column field="partnerName" header="Beszállító" sortable filter filterPlaceholder="Beszállító"></Column>
        <Column header="Szállítólevél" body={(rowData) => {
          const state = 'valueDeliveryNoteId' + rowData.id
          return (
            <>
              {rowData.deliveryNote && rowData.deliveryNote.id ? (
                <Dropdown options={receipts}
                          optionLabel="receiptNumber"
                          optionValue="id"
                          id={"receipt"}
                          emptyMessage={"Még nincs szállítólevél..."}
                          className={"w-100"}
                          onChange={(e) => {
                            let _items = items
                            let itemIndex = _items.findIndex((item => item.id == rowData.id))
                            _items[itemIndex].deliveryNote = {id: e.value}
                            setItems(_items)
                            window.App.forceUpdate()
                          }}
                          value={rowData.deliveryNote.id}
                />
              ) : (
                <Dropdown options={receipts}
                          optionLabel="receiptNumber"
                          optionValue="id"
                          id={"receipt"}
                          emptyMessage={"Még nincs szállítólevél..."}
                          className={"w-100"}
                          onChange={(e) => {
                            let _items = items
                            let itemIndex = _items.findIndex((item => item.id == rowData.id))
                            _items[itemIndex].deliveryNote = {id: e.value}
                            setItems(_items)
                            window.App.forceUpdate()
                          }}
                          value={state}

                />
              )}
            </>
          )
        }}></Column>
        <Column header="Raktár" body={(rowData) => {
          return (
            <>
              {rowData.warehouse && rowData.warehouse.id ? (
                <Dropdown options={warehouses}
                          filter
                          optionLabel="name"
                          optionValue="id"
                          id={"warehouse"}
                          emptyMessage={"Még nincs raktár..."}
                          className={"w-100"}
                          onChange={(e) => {
                            let _items = items
                            let itemIndex = _items.findIndex((item => item.id == rowData.id))
                            _items[itemIndex].warehouse = {id: e.value}
                            setItems(_items)
                            window.App.forceUpdate()
                          }}
                          value={rowData.warehouse.id}
                />
              ) : (
                <Dropdown options={warehouses}
                          filter
                          optionLabel="name"
                          optionValue="id"
                          id={"warehouse"}
                          emptyMessage={"Még nincs raktár..."}
                          className={"w-100"}
                          onChange={(e) => {
                            let _items = items
                            let itemIndex = _items.findIndex((item => item.id == rowData.id))
                            _items[itemIndex].warehouse = {id: e.value}
                            setItems(_items)
                            window.App.forceUpdate()
                          }}
                />
              )}
            </>
          )
        }}></Column>
        <Column header="Érkezett mennyiség" body={(rowData) => {
          return (
            <>
              <InputNumber id="income"
                           locale="hu-HU"
                           minFractionDigits={0}
                           maxFractionDigits={9}
                           className={""}
                           onChange={(e) => {
                             let _items = items
                             let itemIndex = _items.findIndex((item => item.id == rowData.id))
                             _items[itemIndex].stock = e.value
                             setItems(_items)
                             window.App.forceUpdate()

                           }}
                           value={rowData.stock}
              />
            </>
          )
        }}></Column>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col p-col-align-center"}>
          Alapértelmezett raktár választása:&nbsp;
          <Dropdown options={warehouses}
                    optionLabel="name"
                    optionValue="id"
                    id={"defaultWarehouse"}
                    emptyMessage={"Még nincs raktár..."}
                    onChange={(e) => {
                      if (window.confirm('Figyelem! Az összes tétel raktárat felülírja?')) {
                        let _items = []
                        Object.entries(items).map(function (item, index) {
                          _items.push({
                            ...item[1],
                            warehouse: {
                              id: e.value
                            }
                          })
                        })
                        setItems(_items)
                        setDefaultWarehouse(e.value)
                        PurchaseorderRequestItemsUpdateService(items, props.orderId)
                          .then(response => {
                            window.App.toastShow('success', 'Sikeres mentés!');
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      }
                    }}
                    value={defaultWarehouse}
                    showClear
          />
        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          {props.initialValues.status !== 'arrived' &&
          <>
            <Button type="button" label="Szállítólevél rögzítése" icon="pi pi-plus"
                    className="p-button-success"
                    onClick={() => {
                      window.App.setState({
                        dialogDeliverynote: true
                      })
                      if (localStorage.getItem('defaultCompanyId') && localStorage.getItem('defaultCompanyName')) {
                        setSelectedCompany(localStorage.getItem('defaultCompanyName'))
                        setSelectedPartner('')
                        setFormData({
                          company: {id: Number(localStorage.getItem('defaultCompanyId'))}
                        })
                      } else {
                        setFormData({})
                        setSelectedPartner('')
                      }
                    }}
            />
            {props.initialValues.status === 'ordered' &&
            <Button type="button" label="Beszerzés lezárása" icon={"pi pi-check"} loading={loading}
                    onClick={() => {
                      PurchaseorderStatusChangeArrivedService(props.orderId, true)
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres lezárás!');
                          PurchaseorderShowService(props.orderId)
                            .then(response => {
                              window.App.setState({
                                props: response.data,
                                rerenderPurchaseorderList: true,
                                rerenderPurchaseorderShow: true,
                              })
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }}
                    className="p-button-warning p-ml-2"/>
            }
            <Button type="submit" label="Mentés" icon={"pi pi-check"}
                    onClick={() => {
                      PurchaseorderRequestItemsUpdateService(items, props.orderId)
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres mentés!');
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }}
                    className="p-button-success p-ml-2"/>
          </>
          }
        </div>
      </div>
      <Dialog visible={window.App.state.dialogDeliverynote}
              style={{width: '750px'}}
              header="Szállítólevél rögzítése"
              modal
              onHide={() => {
                window.App.setState({
                  dialogDeliverynote: false
                })
              }}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-4"}>
                <Field name="company" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="company"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Vevő</label>
                    <span className={"p-d-block"}>
                            <AutoComplete value={selectedCompany}
                                          id={"company"}
                                          suggestions={companies}
                                          forceSelection
                                          completeMethod={searchCompany}
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              company: {id: e.value.id}
                                            })
                                            localStorage.setItem('defaultCompanyId', e.value.id)
                                            localStorage.setItem('defaultCompanyName', e.value.name)
                                          }}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              company: {id: null}
                                            })
                                            setSelectedCompany(e.value)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedCompany('')
                                          }}
                            />
                          </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <Field name="partner" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="partner"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Szállító</label>
                    <span className={"p-d-block"}>
                    <AutoComplete value={selectedPartner}
                                  id={"partner"}
                                  suggestions={partners}
                                  forceSelection
                                  completeMethod={searchPartner}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      partner: {id: e.value.id}
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      partner: {id: null}
                                    })
                                    setSelectedPartner(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedPartner('')
                                  }}
                    />
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <Field name="receiptGroup" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="receiptGroup"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Számlatömb</label>
                    <span className={"p-d-block"}>
                  {formData.receiptGroup && formData.receiptGroup.id ? (
                    <Dropdown {...input}
                              value={formData.receiptGroup.id}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  receiptGroup: {id: e.value}
                                })
                              }}
                              options={receiptgroups}
                              optionLabel="name"
                              optionValue="id"
                              id={"receiptGroup"}
                              emptyMessage={"Még nincs szállítólevél típusú számlatömb..."}
                              className={classNames({'p-error': isFormFieldValid(meta)})}
                              showClear
                    />
                  ) : (
                    <Dropdown {...input}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  receiptGroup: {id: e.value}
                                })
                              }}
                              options={receiptgroups}
                              optionLabel="name"
                              optionValue="id"
                              id={"receiptGroup"}
                              emptyMessage={"Még nincs szállítólevél típusú számlatömb..."}
                              className={classNames({'p-error': isFormFieldValid(meta)})}
                              showClear
                    />
                  )}
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <Field name="deliveryDate" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="deliveryDate"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Teljesítés napja</label>
                    <span className={"p-d-block"}>
                      <InputMask id={"deliveryDate"}
                                 mask={"9999-99-99"}
                                 placeholder={"ÉÉÉÉ-HH-NN"}
                                 value={formData.deliveryDate}
                                 onChange={(e) => {
                                   if (e.value.indexOf("_") === -1) {
                                     if (e.value === '') {
                                       e.value = null
                                     }
                                     setFormData({
                                       ...formData,
                                       deliveryDate: e.value
                                     })
                                   }
                                 }}
                      />

                      </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <Field name="issueDate" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="issueDate"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Kiállítás napja</label>
                    <span className={"p-d-block"}>
                      <InputMask id={"issueDate"}
                                 mask={"9999-99-99"}
                                 placeholder={"ÉÉÉÉ-HH-NN"}
                                 value={formData.issueDate}
                                 onChange={(e) => {
                                   if (e.value.indexOf("_") === -1) {
                                     if (e.value === '') {
                                       e.value = null
                                     }
                                     setFormData({
                                       ...formData,
                                       issueDate: e.value
                                     })
                                   }
                                 }}
                      />
                      </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <Field name="receiptNumber" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="receiptNumber"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Bizonylat szám</label>
                    <span className={"p-d-block"}>
                      <InputText id="receiptNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     receiptNumber: e.target.value
                                   })
                                 }}
                                 value={formData.receiptNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12"}>
                <Field name="partnerNote" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="partnerNote"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Megjegyzés</label>
                    <span className={"p-d-block"}>
                      <InputText id="partnerNote" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     partnerNote: e.target.value
                                   })
                                 }}
                                 value={formData.partnerNote}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                        className="p-button-success p-ml-2"/>
              </div>
            </div>
          </form>
        )}/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(PurchaseorderStockComponent);
