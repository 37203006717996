import React, {useState, useEffect} from 'react';
import axios from "axios";
import {withTranslation} from 'react-i18next';
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";

const WorkitemListComponent = (props) => {

  const {t} = props;
  const [workitems, setWorkitems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderWorkitemList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/work_item/?" + JSON.stringify(lazyParams).replace('"multiSortMeta":[],', ''),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setWorkitems(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderWorkitemList === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={workitems}
        paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} lazy
        paginatorTemplate={pager} rows={rows}
        loading={loading}
        emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
      >
        <Column field="projectName" header={t('project')} sortable></Column>
        <Column field="projectItemName" header={"Tétel"} sortable></Column>
        <Column field="projectNumber" header={t('projectNumber')} sortable></Column>
        <Column field="workflowName" header={"Munkafolyamat"} sortable></Column>
        <Column field="employeeName" header={"Munkavállaló"} sortable></Column>
        <Column field="statusName" header="Státusz" sortable></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(WorkitemListComponent);
